import React from "react";
import CustomLink from "../components/CustomLink";

class HubspotContactForm extends React.Component {
  componentDidMount() {
    const { region, portalId, formId, targetId = "hubspotForm" } = this.props;
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: `#${targetId}`,
        });
      }
    });
  }
  render() {
    const { targetId = "hubspotForm" } = this.props;
    return (
      <div>
        <div id={targetId} style={{ minHeight: "100px" }}>
          {this.props?.loader ? <>{this.props?.loader}</> : <></>}
        </div>
        <div className="text-xs leading-4 text-slate-600">
          Encord needs the contact information you provide to us to contact you
          about our products and services. For information on how to
          unsubscribe, as well as our privacy practices and commitment to
          protecting your privacy, please review our{" "}
          <CustomLink
            to={"/privacy/"}
            className="text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
          >
            Privacy Policy
          </CustomLink>
          .
        </div>
      </div>
    );
  }
}

export default HubspotContactForm;
