import React from "react";
import HubspotContactForm from "./HubspotContactForm";

export const HubspotContactCard = ({ formId, targetId }) => {
  return (
    <div className="bg-white rounded-[14px] max-w-[452px] shadow-2xl px-4 pt-4 pb-8 mx-auto mt-10 md:mt-0 md:ml-auto">
      <div className="mt-8">
        <HubspotContactForm
          targetId={targetId}
          region="eu1"
          portalId="25381551"
          formId={formId}
        />
      </div>
    </div>
  );
};

export default HubspotContactCard;
