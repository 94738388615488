import React from "react";
import PageIllustration from "../partials/PageIllustration";

export default function RightColours() {
  return (
    <div
      className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
      aria-hidden="true"
    >
      <PageIllustration
        primaryColor={"#abacee"}
        primarySecondColor={"#abacee"}
        secondaryColor={"#eeedab"}
        secondarySecondColor={"#eeedab"}
        className={
          "absolute top-0 right-0 transform translate-x-1/2 -mr-16 dark:opacity-40"
        }
      />
    </div>
  );
}
