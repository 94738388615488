import PageIllustrationSecond from "../partials/PageIllustrationSecond";
import React from "react";

export default function LeftColours() {
  return (
    <div
      className="relative max-w-full mx-auto h-0 pointer-events-none z-[-2]"
      aria-hidden="true"
    >
      <PageIllustrationSecond
        primaryColor={"#fcfbee"}
        primarySecondColor={"#fcfbee"}
        secondaryColor={"#bbf1d5"}
        secondarySecondColor={"#ddf8ea"}
        align={
          "absolute top-0 left-0 transform translate-x-1/2 -mr-16 dark:opacity-40"
        }
      />
    </div>
  );
}
