import React from "react";
import LeadFormWithAPI from "../components/LeadFormWithAPI";

const Cta = (props) => {
  const { ctaText } = props;
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          {/* CTA box */}
          <div className="bg-purple-300 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl">
            <div className="flex flex-col lg:flex-row justify-between items-center">
              {/* CTA content */}
              <div className="flex-grow mb-6 lg:mr-12 lg:mb-0 text-center lg:text-left">
                <h3 className="h3 !text-[1.5rem] md:!text-[1.75rem] lg:!text-[1.75rem] text-white mb-2">
                  {ctaText ? ctaText : "Ready to accelerate model development?"}
                </h3>
              </div>

              {/* CTA button */}
              <div className="flex-none cta_lead_form">
                <LeadFormWithAPI
                  location={props?.location}
                  pageName={props?.pageName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
