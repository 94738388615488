import React, { useState } from "react";
import { useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Slider = ({ myData }) => {
  const [activeSlide, setActiveSlide] = useState(1);

  const prevSliderHandler = (id) => {
    if (id === 1) {
      setActiveSlide(myData.length);
    } else if (id > 1) {
      setActiveSlide(activeSlide - 1);
    } else {
      setActiveSlide(myData.length - 1);
    }
  };

  const nextSliderHandler = (activeSlide) => {
    activeSlide < myData.length
      ? setActiveSlide(activeSlide + 1)
      : setActiveSlide(1);
  };

  useEffect(() => {
    const slide = setInterval(() => {
      nextSliderHandler(activeSlide);
    }, 6000);

    return () => clearInterval(slide);
  }, [activeSlide]);

  return (
    <div className="my-6">
      {myData?.map(({ icon, quote }, index) => {
        // const image = getImage(icon);
        return (
          <div
            key={index + 1}
            className={
              activeSlide === index + 1
                ? "flex justify-between items-center"
                : "hidden"
            }
          >
            <button onClick={() => prevSliderHandler(index + 1)}>
              <svg
                width="11"
                height="17"
                viewBox="0 0 11 17"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M0 8.5L10.5 16.7272L10.5 0.272758L0 8.5Z"
                  fill="#5658DD"
                />
              </svg>
            </button>
            <div className="px-4 min-h-[220px] flex flex-col justify-evenly slide-fadeIn">
              <img
                width={90}
                height="auto"
                className="mx-auto mb-4"
                src={icon?.url}
                alt={icon?.alt || "icon"}
                loading="lazy"
              />
              <div
                className="text-base italic text-black space-y-4 contact-sales-quote"
                dangerouslySetInnerHTML={{ __html: quote?.html }}
              />
            </div>
            <button onClick={() => nextSliderHandler(index + 1)}>
              <svg
                width="11"
                height="17"
                viewBox="0 0 11 17"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M11 8.5L0.5 0.272758L0.5 16.7272L11 8.5Z"
                  fill="#5658DD"
                />
              </svg>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Slider;
